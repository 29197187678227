// Submit form when the user presses CMD+Enter
//
// Example Usage:
// <%= form_with url: "/", data: {controller: "cmd-enter"} do %>
//   <%= text_field_tag :search, nil, data: { cmd_enter_target: "input" } %>
//   <%= submit_tag "Search", data: { cmd_enter_target: "submit" } %>
// <% end %>

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "submit"];

  submit(event) {
    if (!((event.metaKey || event.ctrlKey || event.keyCode == 91 || event.keyCode == 93) && event.keyCode == 13))
      return;

    this.submitTarget.click()
  }
}

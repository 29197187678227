// Visits the URL contained in the selected option value field
//
// Example Usage:
// <select data-controller="select-redirect">
//   <option value="https://example.com">Example</option>
// </select>

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.element.addEventListener('change', (e) => {
      let frame = this.element.closest('turbo-frame');
      let url = e.target.value;
      let options = { frame: frame?.id }

      Turbo.visit(url, options)

      // Setting action on options does not with with turbo frames.
      if (frame?.dataset.turboAction == "advance") {
        history.pushState({}, null, url);
      }
      else if (frame?.dataset.turboAction == "replace") {
        history.replaceState({}, null, url);
      }
    })
  }
}

// Append selected value to the URL to filter results
//
// Example Usage:
//
// Can be used directly on a select:
//
// <select name="value" data-controller="select-filter">
//   <option value="1">Option 1</option>
//   <option value="2">Option 2</option>
// </select>
//
// Or on a parent element to handle multiple selects:
//
// <div data-controller="select-filter">
//   <select name="value1">...</select>
//   <select name="value2">...</select>
// </div>

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    if (this.element.tagName === 'SELECT') {
      this.element.addEventListener('change', this.selected.bind(this));
    } else {
      this.element.querySelectorAll('select').forEach(select => {
        select.addEventListener('change', this.selected.bind(this));
      });
    }
  }

  disconnect() {
    if (this.element.tagName === 'SELECT') {
      this.element.removeEventListener('change', this.selected.bind(this));
    } else {
      this.element.querySelectorAll('select').forEach(select => {
        select.removeEventListener('change', this.selected.bind(this));
      });
    }
  }

  selected(event) {
    let target = event.target;
    let paramName = target.getAttribute('name');
    let queryParams = new URLSearchParams(window.location.search);
    queryParams.set(paramName, target.value);
    window.location.search = queryParams.toString();
  }
}

// Hack to position the dropdown outside of the parent div to allow it to overflow beyond the y axis
// See: https://stackoverflow.com/a/74749062
// and: https://codepen.io/tammibriggs/pen/gOKyWgG
//
// Example Usage:
// <div data-controller="fix-dropdown-position">
//   <select name="value">...</select>
// </div>

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    let dropdown = this.element;
    let parent = dropdown.parentElement;
    dropdown.style.left = parent.left + 'px';
    dropdown.style.position = 'fixed';
  }
}

// Signature pad allowing users to draw their signature
//
// Example Usage:
// <div data-controller="signature">
//   <canvas data-signature-target="canvas"></canvas>
//   <input type="hidden" data-signature-target="input">
//   <button data-action="click->signature#clear">Clear</button>
// </div>


import { Controller } from "@hotwired/stimulus"
import SignaturePad from 'signature_pad'

export default class extends Controller {
  static targets = ["canvas", "input"]
  static values = { disabled: { type: Boolean, default: false } }

  connect() {
    this.signaturePad = new SignaturePad(this.canvasTarget)
    this.signaturePad.addEventListener("endStroke", this.endStroke)
    this.resizeCanvas()

    if (this.inputTarget.value) {
      this.signaturePad.fromDataURL(this.inputTarget.value)
      this.endStroke()
    }

    if (this.disabledValue) {
      this.signaturePad.off()
    }
  }

  disconnect() {
    this.signaturePad.off()
  }

  clear() {
    if (this.disabledValue) return
    this.signaturePad.clear()
    this.inputTarget.value = ""
  }

  endStroke = (_) => {
    this.inputTarget.value = this.signaturePad.toDataURL("image/svg+xml")
  }

  resizeCanvas() {
    // When zoomed out to less than 100%, for some very strange reason,
    // some browsers report devicePixelRatio as less than 1
    // and only part of the canvas is cleared then.
    var ratio = Math.max(window.devicePixelRatio || 1, 1)
    this.canvasTarget.width = this.canvasTarget.offsetWidth * ratio
    this.canvasTarget.height = this.canvasTarget.offsetHeight * ratio
    this.canvasTarget.getContext("2d").scale(ratio, ratio)
  }

  validate() {
    return !this.signaturePad.isEmpty()
  }
}

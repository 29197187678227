// Disable form after submission to prevent double submit
//
// Example Usage:
// <%= form_with url: "/", data: { controller: "disable-submission", disable_submission_with_value: "Submitting..." } %>

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { with: String };

  connect() {
    this.element.dataset["action"] ||= "";
    this.element.dataset["action"] += " submit->disable-submission#disableForm";
    this.element.dataset["action"].trim();
  }

  disableForm() {
    this.submitButtons().forEach((button) => {
      button.disabled = true;
      if (this.hasWithValue) button.value = this.withValue;
    });
  }

  submitButtons() {
    return this.element.querySelectorAll("input[type='submit'], button[type='submit']");
  }
}

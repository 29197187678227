// Handles instant file uploads with drag & drop support and HEIC image conversion
//
// The controller will:
// - Enable drag & drop file uploads
// - Show upload progress
// - Convert HEIC images to JPEG
// - Trigger events when upload completes

// Example Usage:
// <div data-controller="instant-upload"
//      data-instant-upload-target="container">
//
//   <input type="file"
//          data-instant-upload-target="input"
//          data-action="instant-upload#changed">
//
//   <img data-instant-upload-target="image">
//
//   <span data-instant-upload-target="status"></span>
// </div>

import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage"
import heic2any from "heic2any"

export default class extends Controller {
  static targets = ["container", "input", "image", "status"]

  connect() {
    this.addDragAndDropEvents()
    this.data.set("in-progress", false)
  }

  addDragAndDropEvents() {
    this.containerTarget.addEventListener('dragover', this.dragover.bind(this))
    this.containerTarget.addEventListener('dragleave', this.dragleave.bind(this))
    this.containerTarget.addEventListener('drop', this.drop.bind(this))
  }

  dragover(event) {
    event.preventDefault()
    this.data.set("dragging", true)
  }

  dragleave(event) {
    event.preventDefault()
    this.data.set("dragging", false)
  }

  drop(event) {
    event.preventDefault()
    const files = event.dataTransfer.files
    if (files.length > 0) {
      this.inputTarget.files = files
      this.processFiles()
    }
  }

  changed() {
    this.processFiles()
  }

  processFiles() {
    this.data.set("in-progress", true)

    Array.from(this.inputTarget.files).forEach(file => {
      file.type.startsWith("image/heic") || file.type.startsWith("image/heif")
        ? this.convertAndUpload(file)
        : this.uploadFile(file)
    })
  }

  convertAndUpload(file) {
    this.statusTarget.innerHTML = "Converting..."

    heic2any({ blob: file, toType: "image/jpeg", quality: 0.8 })
      .then(conversionResult => this.uploadFile(conversionResult, file.name))
      .catch(error => {
        this.statusTarget.innerHTML = "Error converting HEIC to JPEG: " + error
        console.error("Error converting HEIC to JPEG", error)
      }
      )
  }

  uploadFile(file, originalFilename = null) {
    const filename = originalFilename ? originalFilename.replace(/\.\w+$/, ".jpg") : file.name
    const blobFile = new File([file], filename, { type: "image/jpeg" })

    this.statusTarget.innerHTML = "Uploading..."

    new DirectUpload(blobFile, this.postURL()).create((error, blob) => {
      if (error) {
        this.statusTarget.innerHTML = "Error uploading file: " + error
        console.error("Error uploading file:", error)
      } else {
        this.updateUIAfterUpload(blob)
      }
    })
  }

  updateUIAfterUpload(blob) {
    this.data.set("in-progress", false)
    this.statusTarget.innerHTML = ""
    this.hiddenInput().value = blob.signed_id
    this.inputTarget.type = "hidden"
    this.imageTarget.src = `${this.getURL()}/${blob.signed_id}/${blob.filename}`
    this.imageTarget.dispatchEvent(this.event())
  }

  hiddenInput() {
    if (!this._hiddenInput) {
      this._hiddenInput = document.createElement('input')
      this._hiddenInput.name = this.inputTarget.name
      this._hiddenInput.type = "hidden"
      this.inputTarget.parentNode.insertBefore(this._hiddenInput, this.inputTarget.nextSibling)
    }
    return this._hiddenInput
  }

  event() {
    if (!this._event) {
      this._event = new CustomEvent("instant-upload-finished", { bubbles: true, cancelable: true })
    }
    return this._event
  }

  postURL() {
    return '/rails/active_storage/direct_uploads'
  }

  getURL() {
    return '/rails/active_storage/blobs/redirect'
  }
}

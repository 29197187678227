// Toggles the visibility of the target content
//
// Example Usage:
// <div data-controller="toggler">
//   <button data-action="click->toggler#toggle">Toggle</button>
//   <div data-toggler-target="content" data-toggler-class="hidden">...</div>
// </div>

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["content"];

  toggle() {
    this.contentTargets.forEach(function (t) {
      let c = t.dataset.togglerClass;
      t.classList.toggle(c);
    });
  }
}

// Simple interactivity for the enrollment guardians search form

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["searchField", "relationshipDiv", "createRecordButton", "assignSelectedButton"]

  searchValueChanged(event) {
    let uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    if (uuidRegex.test(event.target.value)) {
      this.relationshipDivTarget.classList.remove("hidden");
      this.createRecordButtonTarget.classList.add("hidden");
      this.assignSelectedButtonTarget.classList.remove("hidden");
    } else if (event.target.value.length > 0) {
      this.relationshipDivTarget.classList.remove("hidden");
      this.createRecordButtonTarget.classList.remove("hidden");
      this.assignSelectedB
    } else {
      this.relationshipDivTarget.classList.add("hidden");
      this.createRecordButtonTarget.classList.add("hidden");
      this.assignSelectedButtonTarget.classList.add("hidden");
    }
  }
}
